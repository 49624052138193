import * as React from "react";
import Layout from '../../components/layout-en';
import Container from '../../components/container';
import {StaticImage} from "gatsby-plugin-image";

class ChangeShapersPage extends React.Component {
    constructor(props) {
        super(props);

        const faces = [
            {
                id: 'borys-sosnicki',
                img: (<StaticImage src="../../images/changeshapers/BorysSosnicki.jpg" alt="Borys Sośnicki" />),
                name: 'Borys Sośnicki',
                desc: (<>
                    <p>I come from Sieradz - a small town, which, however, can boast of a very rich history. For the past three years, I have been living in Warsaw, where I study at the Warsaw School of Economics, enjoying a great passion for student organizations.</p>
                    <p>I am a committed activist and at the same time the president of the Erasmus Student Network at my university, while also being actively involved in ESN at the national level and slowly making my way to the international level. </p>
                    <p>In my free time I go for professional alcohol tastings. I am also in possession of the largest collection of souvenirs of the now defunct "Polmos" Sieradz Spirits Industry. In moments of relaxation I usually read, watch animations and Marvel movies or play computer games.</p>
                    <p>I can't wait to give lectures in high schools. I get hot flashes on my face when I think about the fact that I will be able to infect the next generation with the passion for social activity and simply change the world for the better.</p>
                </>)
            },
            {
                id: 'wawrzyniec-sokolowski',
                img: (<StaticImage src="../../images/changeshapers/WawrzyniecSokołowski.jpg" alt="Wawrzyniec Sokołowski" />),
                name: 'Wawrzyniec Sokołowski',
                desc: (<>
                    <p>I come from a small town of Milicz, located about 60 km from Wroclaw. In the past I tried to become a doctor at Umeda in Wroclaw, but with time I discovered cognitive science, which I am currently studying at the Jagiellonian University. </p>
                    <p>As you can guess from the name of my major, I am fascinated by people. I develop this interest in AEGEE-Krakow, one of the Polish branches of the international student organization, where I participate in groups and help organize projects.</p>
                    <p>I know how frightening and paralyzing can be the enormity of the possibilities of modernity. That is why I want to share with young people a different perspective on the world today, as well as a spark of fascination and enthusiasm for living according to their own patterns. All thanks to discovering and using these possibilities.</p>
                </>)
            },
            {
                id: 'martyna-zapalska',
                img: (<StaticImage src="../../images/changeshapers/MartynaZapalska.jpg" alt="Martyna Zapalska" />),
                name: 'Martyna Zapalska',
                desc: (<>
                    <p>I'm from Cracow, currently studying Management of Culture and Media at Jagiellonian University. </p>
                    <p>My life is not only occupied with classes and exams. I am actively involved in the AEGEE-Krakow organization, of which I am the proud president. </p>
                    <p>I am interested in foreign languages, photography and from time to time I go sailing. </p>
                    <p>I know how scary life choices can be and how even scarier the change itself can be. Nevertheless, in my opinion, nothing happens by chance, so I believe that from every experience and action you can take something and use it for your development. And that's how I approach #ChangeShapers ;)</p>
                </>)
            },
            {
                id: 'natalia-szczucka',
                img: (<StaticImage src="../../images/changeshapers/NataliaSzczucka.jpg" alt="Natalia Szczucka" />),
                name: 'Natalia Szczucka',
                desc: (<>
                    <p>I come from Cracow, but my body and soul are in Warsaw. Although I was born and raised in the former capital, nowadays I live and reside in the current one. Also studying Financial Management at Leon Koźmiński University.</p>
                    <p>On a daily basis, I also work for the company ConQuest Consulting. In the university environment I am a founder of SKN Women in Organisations, an initiative functioning within my university.</p>
                    <p>Focused on continuous development, both technological and medical. I am interested in medtech solutions and digital health. When I need a break from everyday duties, I indulge in cooking, literature and water sports.</p>
                </>)
            },
            {
                id: 'rafal-gorniak',
                img: (<StaticImage src="../../images/changeshapers/RafalGoorniak.jpg" alt="Rafał Górniak" />),
                name: 'Rafał Górniak',
                desc: (<>
                    <p>I come from Lodz and currently live there, studying Information Technology at Technical University of Lodz. In my free time I am occupied with programming, which turns out to be very useful during classes.</p>
                    <p>I try to get as much out of the academic period as possible. I am actively involved in the ESN-EYE organization and in the Local Government of the Technical University. It is this enthusiasm that I only wish to further during the #ChangeShapers program. </p>
                    <p>Interested in sports in nearly every form, especially tennis and swimming (I train running myself). In addition - here is an interesting fact - I really enjoy taking care of the plants in my garden ;)</p>
                </>)
            },
            {
                id: 'krzesimir-koniecko',
                img: (<StaticImage src="../../images/changeshapers/KrzesimirKoniecko.jpg" alt="Krzesimir Koniecko" />),
                name: 'Krzesimir Koniecko',
                desc: (<>
                    <p>A proud child of Gdynia, the city of sea and dreams. Therefore, I could not leave Tricity. Therefore I study Environmental Engineering at Gdansk University of Technology. </p>
                    <p>My activity in student life is not limited to one place and one organization. I express my passion for the academic community through the Scientific Circle, the Faculty Student Council and the oldest student government in Poland. </p>
                    <p>I love sports, especially lacrosse, while also having passion for RPG and dancing. My life is guided by the idea that the sky is not the limit, and the only limit is the one we set ourselves.</p>
                </>)
            },
            {
                id: 'paulina-natkaniec',
                img: (<StaticImage src="../../images/changeshapers/PaulinaNatkaniec.jpg" alt="Paulina Natkaniec" />),
                name: 'Paulina Natkaniec',
                desc: (<>
                    <p>I come from Piotrkow Trybunalski, but for the last four years I have been living and studying in Cracow. A few months ago, I defended my engineering degree at the AGH University of Science and Technology, majoring in Management and Production Engineering. Currently I am in the process of completing my Master's degree in the same field.</p>
                    <p>I am a member of two student organizations: Board of European Students of Technology and Academic Council of AGH Student Government (projects committee). </p>
                    <p>For years I have been passionate about horse riding, I also love rollerblading and inline skating. For some time I've also been fascinated by the world of plants and I've managed to gather quite a nice collection :) </p>
                    <p>I would like to show that every student organization is about positively twisted, incredibly smiling people and also a lot of possibilities.</p>
                </>)
            },
            {
                id: 'aleksandra-milewska',
                img: (<StaticImage src="../../images/changeshapers/AleksandraMilewska.jpg" alt="Aleksandra Milewska" />),
                name: 'Aleksandra Milewska',
                desc: (<>
                    <p>A crazy second-year student of Economics at the Faculty of Economics, University of Warsaw. I come from a small village in Mazovia, currently living in Warsaw's Wola district.</p>
                    <p>I am an active member of the Student Government of my department, where I belong to the Project Committee and Business and External Relations Committee.</p>
                    <p>I define myself as an adrenaline enthusiast. Any physical activity gives me great satisfaction: gym, fitness, bicycle in summer, skiing in winter.</p>
                    <p>I am a sociable and self-confident person who is not afraid of challenges. I never give up, because I know that after difficult moments in life always better ones will come. My life motto is: "Affirmation is the key to success".</p>
                </>)
            },
            {
                id: 'karolina-folta',
                img: (<StaticImage src="../../images/changeshapers/KarolinaFolta.jpg" alt="Karolina Folta" />),
                name: 'Karolina Folta',
                desc: (<>
                    <p>I come from Brzozów in Podkarpacie. However, my life brought me to Malopolska, where I am currently studying Mechatronic Engineering at AGH University of Science and Technology in Krakow.</p>
                    <p>I have been present in academic circles for several years. For more than three years I have been active in student organization BEST AGH Krakow, mainly contacting companies interested in cooperation in our initiatives.</p>
                    <p>In my free time I love rock concerts and spending time actively. Of course with my friends :)</p>
                </>)
            },
            {
                id: 'maksymilian-czekaj',
                img: (<StaticImage src="../../images/changeshapers/MaksymilianCzekaj.jpg" alt="Maksymilian Czekaj" />),
                name: 'Maksymilian Czekaj',
                desc: (<>
                    <p>I come from Cracow, but I live in Warsaw. Yes, it is possible ;)</p>
                    <p>Currently studying Management in terms of Business and Media Sociology at Leon Koźmiński Academy. For a year I studied at the Academy of Physical Education in Krakow, but over time my priorities have changed and shifted significantly towards business.</p>
                    <p>Currently I am active in the university government as a marketing supervisor. My biggest passion is sports, especially football. I am also interested in the hairdressing industry: I cut hair and beards personally and almost professionally, being really fascinated with the activity.</p>
                    <p>In my opinion, you have to ask yourself one very important question: "What do you want to do in life?". And start doing it.</p>
                </>)
            },
            {
                id: 'alina-paharzhelskaya',
                img: (<StaticImage src="../../images/changeshapers/Alina_Paharzhelskaya.jpg" alt="Alina Paharzhelskaya" />),
                name: 'Alina Paharzhelskaya',
                desc: (<>
                    <p>I am from Belarus, currently studying Management and Sociology in Business and Media at Leon Kozminski Academy in Warsaw.</p>
                    <p>I am a member of the PR and Marketing Department of the Student Government and a member of the scientific association Women in organizations.</p>
                    <p>Interested in psychology, graphic design and art history.</p>
                    <p>During the program I will present twelve reasons why it is worth being active in student organizations. I will also talk about internal processes, which other participants will surely come across. It's worth the wait ;)</p>
                </>)
            },
            {
                id: 'iga-swiatek',
                img: (<StaticImage src="../../images/changeshapers/Iga_Swiatek.jpg" alt="Iga Świątek" />),
                name: 'Iga Świątek',
                desc: (<>
                    <p>Hi, this is Iga from the vicinity of Jaslo. I am currently studying Industrial Process Engineering and Management at AGH University of Science and Technology in Krakow. </p>
                    <p>My academic specialization is Manufacturing Process Controlling. For about two years I have been an active member of the Projects Committee of the AGH Student Government Association, as well as the Student Scientific Association "Management" and ESN AGH Krakow. </p>
                    <p>However, life is not only about studies. Besides, I am interested in digital marketing, music and event organization. I will share each of these passions during the #ChangeShapers program ;)</p>
                </>)
            },
            {
                id: 'natalia-biernacka',
                img: (<StaticImage src="../../images/changeshapers/NataliaBiernacka.jpg" alt="Natalia Biernacka" />),
                name: 'Natalia Biernacka',
                desc: (<>
                    <p>Only one city in my head and heart. I come from Lodz. I live in Lodz. I study in Lodz, Applied Mathematics at the Technical University to be precise.</p>
                    <p>During my second semester of studies, I decided to get involved in the activities of the Faculty Council of Self-Government and I applied to BEST Lodz Students Association. With success ;)</p>
                    <p>I love social engagement, traveling and meeting new people. Would like to show upcoming students that the future depends only on us while proving that the limitations are only in our heads.</p>
                </>)
            },
            {
                id: 'martyna-wawrzen',
                img: (<StaticImage src="../../images/changeshapers/MartynaWawrzen.jpg" alt="Martyna Wawrzeń" />),
                name: 'Martyna Wawrzeń',
                desc: (<>
                    <p>I come from Silesia, specifically from Żory. I was born there and finished elementary school. Currently, I study Management Engineering at the Technical University of Lodz. </p>
                    <p>I moved to a big city to try my hand at an independent life. It so happens that I can't sit still, so as soon as the chance came, I started to act in BEST Students Association at the Technical University of Lodz. I gained a lot of experience through many pieces of training and project work.</p>
                    <p>In my free time, I am interested in photography, traveling, and conscious fashion. </p>
                    <p>During meetings, I would like to talk about my life journey and how self-confidence and openness to people helped me to change my environment so often.</p>
                </>)
            },
            {
                id: 'hubert-swierad',
                img: (<StaticImage src="../../images/changeshapers/HubertSwierad.jpg" alt="Hubert Świerad" />),
                name: 'Hubert Świerad',
                desc: (<>
                    <p>I have spent all my life so far in my hometown - Lodz. After graduating from high school my career path was directed toward a technical university - the Technical University of Lodz. </p>
                    <p>During my academic adventure, I started another one, as a part of the European Students' Association BEST PŁ. I fill my free time with sailing and learning film editing. </p>
                    <p>Would like my meetings with other people during the #ChangeShapers program to be a motivation for young people not only to learn but above all to take various actions during their studies.</p>
                </>)
            },
            {
                id: 'pola-stefaniak',
                img: (<StaticImage src="../../images/changeshapers/PolaStefaniak.jpg" alt="Pola Stefaniak" />),
                name: 'Pola Stefaniak',
                desc: (<>
                    <p>I come from Gdansk, but my academic life took me to Warsaw. I study Management in English at the Leon Koźmiński Academy.</p>
                    <p>Of course, I am also active in student organizations. I am the President of the Board of the Student Government of Kozminski University.</p>
                    <p>When asked what I do for a living, I always answer briefly and concisely: "I love motoring and dogs". And nothing in this matter has changed ;)</p>
                </>)
            },
            {
                id: 'karolina-olender',
                img: (<StaticImage src="../../images/changeshapers/KarolinaOlender.jpg" alt="Karolina Olender" />),
                name: 'Karolina Olender',
                desc: (<>
                    <p>Hi, my name is Karolina and I live in Warsaw, studying Management at the University of Warsaw ;)</p>
                    <p>Currently, I am active in the Student's Scientific Group of Finance at the University of Warsaw.</p>
                    <p>Maps are my greatest passion and I also work with them on a daily basis. I also love traveling - soon planning my third Erasmus trip because I really like living in other countries and getting to know their culture and society. </p>
                    <p>People are the biggest inspiration for me. Especially the ones I surround myself with.</p>
                </>)
            },
            {
                id: 'filip-bogdal',
                img: (<StaticImage src="../../images/changeshapers/Filip_Bogdal.jpg" alt="Filip Bogdał" />),
                name: 'Filip Bogdał',
                desc: (<>
                    <p>I come from and live in Cracow. Currently, I am a student at the University of Economics, studying Finance and Accounting in the first year of complementary master's studies.</p>
                    <p>For the past three years, I have been a member of the Accounting Research Society, being the president of the organization. Additionally, I am active within the Board of the Young Accountants Club at the Krakow regional branch of the Accountants Association in Poland.</p>
                    <p>My interests include broadly defined accounting, the world of finance, and American football.</p>
                    <p>Through the #ChangeShapers program, I would like to convey to young people how important and fruitful acting in student organizations can be. I believe that it is now the key to success in realizing one's plans and to fully develop during one's studies.</p>
                </>)
            },
            {
                id: 'kamila-bielecka',
                img: (<StaticImage src="../../images/changeshapers/Kamila_Bielecka.jpg" alt="Kamila Bielecka" />),
                name: 'Kamila Bielecka',
                desc: (<>
                    <p>I come from a small town in Lubuskie, but currently living in my beloved Cracow. I have been studying International Management at Jagiellonian University for two years.</p>
                    <p>A member of the AEGEE - the largest international, interdisciplinary student organization in Europe.</p>
                    <p>I am interested in people: in talking to them and learning about their stories and outlook on the world. In my free time, I travel, read books and go for walks. </p>
                    <p>During my presentation, I would like to make young people aware of the many opportunities waiting for them in their life. I would also like to convey the knowledge that it is not worth limiting ourselves to what our friends do or say.</p>
                </>)
            },
            {
                id: 'karolina-brydak',
                img: (<StaticImage src="../../images/changeshapers/Karolina_Brydak.jpg" alt="Karolina Brydak" />),
                name: 'Karolina Brydak',
                desc: (<>
                    <p>Currently living in Rzeszow, therefore studying at the College of Medical Sciences at the University of Rzeszow. </p>
                    <p>What can I say about myself? First of all, a socially active person. I am active in scientific circles Rech-Tech and SKN in geriatrics and health prevention. </p>
                    <p>In my free time, I like to volunteer in various areas. My greatest passion is music, which I develop by studying and creating concerts while conducting community projects.</p>
                    <p>I would like to show my younger colleagues the benefits of undertaking social or scientific activities. I also want to encourage them to look for themselves in different areas, not to limit themselves and allow themselves to realize their own plans and dreams.</p>
                </>)
            },
            {
                id: 'maksymilian-okonski',
                img: (<StaticImage src="../../images/changeshapers/Maks_Okonski.jpg" alt="Maksymilian Okoński" />),
                name: 'Maksymilian Okoński',
                desc: (<>
                    <p>Hi, this is Maksymilian ;)</p>
                    <p>I am from Warsaw, where I live all my life. Currently studying Management in English at Leon Koźmiński Academy.</p>
                    <p>I try to use my academic time as efficiently as possible. Since January 2022, I have been a member of the Student Government in the Minorities and Equality Committee. Additionally, I participate in the Collaborative Online International Learning project with students from Auckland and Amsterdam.</p>
                    <p>I am interested in sports and traveling, I also like foreign languages and spending my time actively. Also during social meetings with friends ;)</p>
                </>)
            },
            {
                id: 'justyna-stanula',
                img: (<StaticImage src="../../images/changeshapers/Justyna_Stanula.jpg" alt="Justyna Stanula" />),
                name: 'Justyna Stanula',
                desc: (<>
                    <p>Proud and academically active student of the third year of pharmacy at Jagiellonian University Medical College in Krakow.</p>
                    <p>Since my first year of studies, I have been active in the Polish Society of Pharmacy Students, an organization that allows you to broaden your knowledge about your future profession and possible career paths. This year, I have the pleasure of being the National Coordinator for contacts with IPSF (International Society of Pharmacy Students). I am also active in the Faculty Student Government Council of the Faculty of Pharmacy at UJCM. </p>
                    <p>I would like to show my younger colleagues that no matter what your field of interest or major is, you can find a fantastic place, develop yourself and learn about your strengths.  </p>
                </>)
            },
            {
                id: 'dominik-surowiec',
                img: (<StaticImage src="../../images/changeshapers/DominikSurowiec.jpg" alt="Dominik Surowiec" />),
                name: 'Dominik Surowiec',
                desc: (<>
                    <p>By blood? From a small town near Rzeszów in Podkarpackie province. Student status? A third year of Entrepreneurship and Finance at the University of Economics in Katowice. Thoughts? At the inauguration of the #ChangeShapers program ;)</p>
                    <p>I am currently the President of the Student Research Group "International Challenge".</p>
                    <p>Interested in psychology (especially issues of multiple minds), Internal Family System, as well as finance, investing and economics. </p>
                    <p>I would like to tell about my own experiences and my story to my younger colleagues. The story of a boy who knew what he wanted to do in life, following his dreams and the situation that changed everything.</p>
                </>)
            },
            {
                id: 'agnieszka-kopacz',
                img: (<StaticImage src="../../images/changeshapers/AgnieszkaKopacz.jpg" alt="Agnieszka Kopacz" />),
                name: 'Agnieszka Kopacz',
                desc: (<>
                    <p>I grew up in Rejowiec, a small town in the Lublin region, from where I went to college in Katowice. There I am studying at the University of Information Technology, majoring in Graphics with a specialization in UX Design - usability design.</p>
                    <p>For me, studying is not only about studying, therefore I am actively involved in the Lorem Ipsum Research Group, which brings together students who wish to create art in the field of graphic design. </p>
                    <p>I am interested in many aspects of graphic design, traditional and artistic printing, as well as photography and human resources management. My hobbies also include mineral collecting, geology, and ecology.</p>
                </>)
            },
            {
                id: 'małgorzata-tobis',
                img: (<StaticImage src="../../images/changeshapers/MałgorzataTobis.jpg" alt="Małgorzata Tobis" />),
                name: 'Małgorzata Tobis',
                desc: (<>
                    <p>A final-year student of entrepreneurship and innovation management at the University of Lodz. </p>
                    <p>While connecting the business world with students, for nearly two years I have been active in the Students' Forum Business Centre Club, where I reactivated the Lodz Region. A while later, I took over the function of a coordinator of the Entrepreneurial Woman project and later on, the vice-president for administrative and financial matters. I have completed many projects: from marathon webinars to business workshops and supporting companies in marketing activities. </p>
                    <p>One who knows me knows that I am a development freak. I will always share information about a valuable training or an upcoming event. During meetings with high school students, I want to share my energy for development and action.</p>
                </>)
            },
            {
                id: 'alisa-kushnyrenko',
                img: (<StaticImage src="../../images/changeshapers/AlisaKushnyrenko.jpg" alt="Alisa Kushnyrenko" />),
                name: 'Alisa Kushnyrenko',
                desc: (<>
                    <p>Hi everyone! I'm Alisa, I came to Poland from Ukraine to study Management at Leon Kozminski University in Warsaw.</p>
                    <p>I am also a coordinator of the Kozminski International Society student organization. I help students coming from abroad to get accustomed to Poland and to like it as much as I once did :) </p>
                    <p>In my free time, I read a lot and learn foreign languages, pursuing my two greatest passions. </p>
                    <p>I joined #ChangeShapers because I wanted to teach my younger colleagues not to be afraid of the future, but to actively shape it according to their preferences.</p>
                </>)
            },
            {
                id: 'aleksandra-banasiak',
                img: (<StaticImage src="../../images/changeshapers/Aleksandra_Banasiak.jpg" alt="Aleksandra Banasiak" />),
                name: 'Aleksandra Banasiak',
                desc: (<>
                    <p>I come from Lodz and there I study Information Technology in English at the Technical University of Lodz.</p>
                    <p>During my second year of studies, I was actively involved in the life of the BEST student association where I managed to gain experience in HR, PR, and FR.</p>
                    <p>I love traveling and getting to know other cultures or new people. Working with people definitely gives me the most pleasure.</p>
                    <p>I would like to pass on to my young colleagues the opportunities and possibilities for development, which should simply be taken as an advantage. My approach to life? It's always worth trying and getting out of your comfort zone.</p>
                </>)
            },
            {
                id: 'dominika-ziolkowska',
                img: (<StaticImage src="../../images/changeshapers/DominikaZiolkowska.jpg" alt="Dominika Ziółkowska" />),
                name: 'Dominika Ziółkowska',
                desc: (<>
                    <p>My name is Dominika, I am 21 years old and come from Świebodzin. Currently studying German philology with a specialization in teaching at the Adam Mickiewicz University in Poznan. </p>
                    <p>Since the beginning of this academic year, I have been a member of the University Scholarship Committee and Buddy Program. </p>
                    <p>Interested in theories of foreign language teaching and effective learning techniques as well as online marketing. I also spend my free time getting involved in various projects and ventures that allow me to gain valuable experience. </p>
                    <p>I want to show others that studying is not limited to just studying in your chosen field of study and it can mean much, much more&hellip;</p>
                </>)
            },
            {
                id: 'barbara-wywial',
                img: (<StaticImage src="../../images/changeshapers/BarbaraWywial.jpg" alt="Barbara Wywiał" />),
                name: 'Barbara Wywiał',
                desc: (<>
                    <p>My name is Basia. I come from the area of Częstochowa, but I moved to Warsaw for my studies and will probably stay here permanently. </p>
                    <p>Currently, studying Finance and Accounting at Leon Koźmiński Academy and being actively involved in the academic Women in Organizations initiative.</p>
                    <p>I love animals (having two cats and a dog), meeting new people, and trying new things. My hobbies include running, swimming, tennis, and reading biographies. </p>
                    <p>I would like to pass on the lack of fear to my younger friends and support them in stepping outside their comfort zone. After all, in life, people most often regret the things they never did.</p>
                </>)
            },
            {
                id: 'marcin-miszkiel',
                img: (<StaticImage src="../../images/changeshapers/MarcinMiszkiel.jpg" alt="Marcin Miszkiel" />),
                name: 'Marcin Miszkiel',
                desc: (<>
                    <p>My name is Marcin. I come from Suwalki, currently studying Computer Science and Econometrics in the second year at Warsaw University.</p>
                    <p>Active member of two organizations: The Student Government of my department and the Residents' Council in the Student House. In the meantime, I work part-time at a coffee shop, where my love for coffee deepened. </p>
                    <p>Additionally, I am interested in data analysis, programming, and athletics, which I trained for nine years.</p>
                    <p>When I think about what I would like to convey during #ChangeShapers, it would definitely be to be brave when taking risks, looking for alternatives, and being open to other people. I believe that everything new teaches us something and contributes to our growth.</p>
                </>)
            },
            {
                id: 'iga-friedrich',
                img: (<StaticImage src="../../images/changeshapers/igafriedrich.jpg" alt="Iga Friedrich" />),
                name: 'Iga Friedrich',
                desc: (<>
                    <p>There is an interesting and unusual story connected with me. Although I come from Szczecin, currently I study Management at the University of Economics in... Wroclaw. </p>
                    <p>At my university, I am also currently active in the local Student Government. </p>
                    <p>In my free time, I read books, watch movies and Formula 1 or meet with my friends. I value moments with my friends as much as those I can spend alone. </p>
                    <p>What life rule do I follow? When a candle lights another candle, nothing is lost. That's why I would like to share my knowledge and experience with other participants of the #ChangeShapers program.</p>
                </>)
            },
            {
                id: 'dominika-szjegiec',
                img: (<StaticImage src="../../images/changeshapers/DominikaSzejgiec.jpg" alt="Dominika Szjegiec" />),
                name: 'Dominika Szjegiec',
                desc: (<>
                    <p>I was born and raised in Lublin, but for the past four years, I have been living in Cracow, studying at Jagiellonian University. Last year, I finished my Bachelor's degree in Latin American Studies, and now I am in the first year of my Master's degree in Sociology. </p>
                    <p>Since the beginning of my studies, I have been active in student clubs and organizations, even co-founded one. For the last two years, I have been acting as a vice-chairman of the Council of Scientific Circles at the Jagiellonian University. </p>
                    <p>In everyday life, I am interested in traveling and the popular culture of South Korea. </p>
                    <p>I would like to encourage future students to overcome their fear and take the first step in learning about student activities. The most important thing for me is that studies can be more than just studying and working together with other students can open us up to new life experiences.</p>
                </>)
            },
            {
                id: 'natalia-ozarek',
                img: (<StaticImage src="../../images/changeshapers/NataliaOzarek.jpg" alt="Natalia Ożarek" />),
                name: 'Natalia Ożarek',
                desc: (<>
                    <p>I come from Piotrkow Trybunalski, but for two years I have been living in Cracow in which I completely fell in love, treating the city as my own. And that's where I am currently studying the second year of Computer Science and Intelligent Systems at AGH University of Science and Technology.</p>
                    <p>As a break from the technical side of my studies, I joined the Student Government Association's Project Committee, which allowed me to completely change my approach to my future career. Opening up several new perspectives by the way.</p>
                    <p>In private, passionate about mountain tourism and Latin American dancing. During my speeches, I would like to talk about how to find oneself in the new student reality and how to make the most of the time spent at university. Do not be afraid to undertake new activities - that's my motto ;)</p>
                </>)
            }
        ];

        this.state = {
            faces: faces
        };
    }

    render () {
        return (
            <Layout>
                <section className="relative pt-[4.8125rem] md:pt-[6.8125rem]">
                    <StaticImage src="../../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
                    <Container>
                        <div className="">
                            <h1 className="text-white text-3xl font-semibold py-8 md:text-6xl md:py-12 lg:text-8xl lg:py-16">ChangeShapers</h1>
                        </div>
                    </Container>
                </section>

                <section className="bg-light-gray">
                    <Container>
                        <ul className="py-12 space-y-8 md:py-16 md:space-y-2">
                            {this.state.faces.map(face => (
                                <li key={face.id} id={face.id} className="bg-white p-6 md:grid md:grid-cols-[auto_11rem] md:gap-4 md:p-12 lg:grid-cols-[auto_16rem]">
                                    <div className="w-44 mx-auto mb-6 md:mx-0 md:mb-0 md:order-2 md:translate-x-12 lg:w-64">
                                        {face.img}
                                    </div>

                                    <div className="md:order-1">
                                        <h3 className="text-2xl font-semibold text-center mb-4 md:text-left md:text-4xl md:mb-6">{face.name}</h3>

                                        <div className="text-sm space-y-2 md:text-lg">
                                            {face.desc}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default ChangeShapersPage;